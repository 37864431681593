import { CommonModule, DOCUMENT, isPlatformBrowser, isPlatformServer, registerLocaleData } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, Optional, PLATFORM_ID, inject, signal } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { fromEvent } from 'rxjs';
import { AuthGuardService } from './services/auth-guard.service';
import { environment } from '../environments/environment';

import { CookiesService } from './services/cookies.service';
import { DateService } from './services/date.service';
import { ChatService } from './services/chat.service';
// import { ScreenService } from '@app/services/screen.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MEDIA_TOKEN } from 'tockens';
import { Partner } from './models/partner/IPartner';
// import { SwUpdate } from '@angular/service-worker';
// import { MatBottomSheet } from '@angular/material/bottom-sheet';
// import { PagerReloadComponent } from './components/pagereload/pagereload.component';
import { FormsModule } from '@angular/forms';
import { ChatModule } from './components/chat/chat.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CookieModule } from './components/cookie/cookie.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import localede from '@angular/common/locales/de';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OvermenuComponent } from './components/overmenu/overmenu.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NewlettermodalComponent } from './components/modals/newlettermodal/newlettermodal.component';
import { MatDialog } from '@angular/material/dialog';
import { GoogleService } from './services/google.service';
import { Meta } from '@angular/platform-browser';
registerLocaleData(localede);
@Component({
    selector: 'clever-reisen',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ChatModule,
        MatTooltipModule,
        MatFormFieldModule,
        CookieModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        OvermenuComponent
    ]
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'suntour.reisen';
    isBrowser = signal<boolean>(false);
    showScrollTopButton = signal<boolean>(false);
    showchatpanel = signal<boolean>(false);
    showChat = signal<boolean>(false);
    hidenNavbar = signal<boolean>(false);
    smallMode = signal<boolean>(false);
    showChatAgent = signal<boolean>(true);
    displaySite = signal<boolean>(true);
    pass: string = '';
    currentPartner: Partner;
    protected secret: string = 'cleveracvis';
    protected partnerid: string = '';
    protected lastposition: number = 0;
    destroyRef = inject(DestroyRef);

    constructor(
        protected adapter: DateAdapter<any>,
        @Inject(PLATFORM_ID) protected platformId: any,
        @Inject(DOCUMENT) protected document: any,
        protected cookieService: CookiesService,
        protected dateService: DateService,
        protected chatService: ChatService,
        protected authguard: AuthGuardService,
        protected metaService: Meta,
        protected googleServie: GoogleService,
        // @Optional() private update: SwUpdate,
        private dialog: MatDialog,
        protected cdr: ChangeDetectorRef,
        // private bottomSheet: MatBottomSheet,
        protected mediaObserver: BreakpointObserver,
        @Optional() @Inject(MEDIA_TOKEN) private media_token: string,
    ) {
        this.isBrowser.set(isPlatformBrowser(this.platformId));
        this.smallMode.set(this.media_token === 'xs');
    }

    // testOpen() {
    //     const win = window.open('about:blank', '_blank');
    //     this.bookingF(win);
    // }

    // async bookingF(win) {
    //     win.location = 'https://www.koenigfenster.de/';
    // }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    ngOnInit(): void {
        this.initMedia();
        if (this.isBrowser()) {
            if (!this.detectRobot()) {
                // this.checkUpdate();
                this.listenOpenChat();
            }
            this.checkHidennavbar();
            this.dateService.initCals();
            this.initScroll();
            this.adapter.setLocale('de-DE');
        }
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId) && (!this.detectRobot())) {
            this.appendStyle('late_styles.css');
            this.appendScript('socketdata.js');
            this.appendScript('lazysizes.min.js');
            this.appendScript('crypto-js.js');
            this.appendScript('moment.min.js').then(loaded => {
                if (loaded) {
                    this.appendScript('moment-timezone.min.js');
                }
            });
        }
        this.checkPartner();
    }

    appendScript(name: string) {
        return new Promise((resolve) => {
            const script = this.document.createElement('script');
            script.src = name;
            script.async = true;
            this.document.head.appendChild(script);
            script.onload = () => {
                resolve(true);
            };
        });
    }

    appendStyle(name: string) {
        const style = this.document.createElement('link');
        style.rel = 'preload';
        style.as = 'style';
        style.type = 'text/css';
        style.href = name;
        this.document.head.appendChild(style);
        const style1 = this.document.createElement('link');
        style1.rel = 'stylesheet';
        style1.type = 'text/css';
        style1.href = name;
        this.document.head.appendChild(style1);
    }

    // async checkUpdate() {
    //     this.update?.checkForUpdate();

    //     this.update?.versionUpdates.pipe(
    //         takeUntilDestroyed(this.destroyRef)
    //     ).subscribe(async evt => {
    //         // console.log('UpdateService: versionUpdates', evt);
    //         switch (evt.type) {
    //             case 'VERSION_DETECTED':
    //                 // console.log(`Downloading new app version: ${evt.version.hash}`);
    //                 break;
    //             case 'VERSION_READY':
    //                 // console.log(`Current app version: ${evt.currentVersion.hash}`);
    //                 // console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
    //                 // eslint-disable-next-line no-undef
    //                 this.updateClient();
    //                 break;
    //             case 'VERSION_INSTALLATION_FAILED':
    //                 // console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
    //                 break;
    //         }
    //     });
    // }

    // updateClient() {
    //     const sheetRef = this.bottomSheet.open(PagerReloadComponent, {
    //         data: 'Update verfügbar, bitte aktualisieren'
    //     });
    //     sheetRef.afterDismissed().pipe(
    //         takeUntilDestroyed(this.destroyRef)
    //     ).subscribe(async data => {
    //         if (data && data.message === 'Abbrechen') {
    //             //
    //         } if (data && data.message === 'Aktualisieren') {
    //             await this.update.activateUpdate();
    //             // eslint-disable-next-line no-undef
    //             location.reload();
    //         }
    //     });
    // }

    async initMedia() {
        if (this.mediaObserver.isMatched(Breakpoints.XSmall)) {
            this.smallMode.set(true);
        }
        if (this.mediaObserver.isMatched(Breakpoints.Small)) {
            this.smallMode.set(true);
        }
        if (this.mediaObserver.isMatched(Breakpoints.Medium)) {
            this.smallMode.set(true);
        }
        if (this.mediaObserver.isMatched(Breakpoints.Large)) {
            this.smallMode.set(false);
        }
        if (this.mediaObserver.isMatched(Breakpoints.XLarge)) {
            this.smallMode.set(false);
        }
        this.cdr.detectChanges();
    }

    async checkPartner() {
        this.authguard.getPartner$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((pp) => {
            if (pp?.partnerid?.length > 0) {
                this.currentPartner = pp;
                this.setInitDatas();
            }
        });
    }

    async setInitDatas() {
        this.showChat.set(!this.currentPartner.hideChat && !this.detectRobot());
        this.cdr.detectChanges();
        this.secret = this.currentPartner.partnersite;
        if (this.secret?.length > 0 && window.localStorage.getItem(this.currentPartner.partnerid + '_pass') !== this.secret) {
            this.displaySite.set(false);
        }
        // this.initGoogleapi(pp);
        if (this.isBrowser()) {
            if (this.showChat) {
                this.chatService.connectToServer();
            }
            // this.checkSendScreen();
            this.gtagdef(this.currentPartner);
            this.adsensemeta(this.currentPartner);
            setTimeout(() => {
                this.checknewsletterpopup();
                this.checkCookis(this.currentPartner);
            }, 500);
        }
    }

    async setextracookis() {
        if (this.currentPartner.usercentricsid?.length > 0) {
            const gaScript = this.document.createElement('script');
            gaScript.setAttribute('async', '');
            gaScript.setAttribute('id', 'usercentrics-cmp');
            gaScript.setAttribute('data-settings-id', this.currentPartner.usercentricsid);
            gaScript.setAttribute('src', `https://web.cmp.usercentrics.eu/ui/loader.js`);

            this.document.documentElement.firstChild.appendChild(gaScript);
        }
    }

    async gtagdef(prt) {
        if (prt.googleanalyticstrackingid?.length > 0) {
            this.googleServie.loadgoogleanalyticsdefault(prt.googleanalyticstrackingid, prt.googleanalyticstrackingwitouttagmanager, prt.hidedefaultcookie);
        }
        if (prt.googletagmgid?.length > 0) {
            this.googleServie.loadGoogleTagManager(prt.googletagmgid, prt.hidedefaultcookie);
        }
    }

    async adsensemeta(prt) {
        if (prt.googleadsenseid?.length > 0) {
            this.metaService.updateTag({ name: 'google-adsense-account', content: prt.googleadsenseid });
        }
    }

    async initGoogleapi(currentPartner) {
        if (currentPartner.googleapikey?.length > 0 && isPlatformBrowser(this.platformId) && !this.detectRobot()) {
            const gaScript = this.document.createElement('script');
            gaScript.setAttribute('defer', '');
            gaScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${currentPartner.googleapikey}&libraries=places&callback=Function.prototype`);

            this.document.documentElement.firstChild.appendChild(gaScript);
        }
    }

    async checkHidennavbar() {
        this.authguard.gethidenHeaderFooter$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((e) => {
            this.hidenNavbar.set(e);
            this.cdr.detectChanges();
        });
    }

    async checknewsletterpopup() {
        if (this.currentPartner.newsletterpopupaktiv) {
            const popupaktiv = window.localStorage.getItem(this.currentPartner.partnerid + '_newsletterpopupaktiv');
            if (popupaktiv !== '1') {
                if (this.smallMode()) {
                    const myppsubs = fromEvent(window, 'scroll').pipe(
                        takeUntilDestroyed(this.destroyRef)
                    ).subscribe((_) => {
                        if (window.scrollY > 500) {
                            this.dialog.open(NewlettermodalComponent, {
                                width: !this.smallMode() ? '800px' : '100%',
                                height: !this.smallMode() ? '' : '100vh',
                                maxWidth: '100%',
                                panelClass: 'customModal',
                                data: {
                                    email: '',
                                    unsubscribe: false
                                }
                            });
                            window.localStorage.setItem(this.currentPartner.partnerid + '_newsletterpopupaktiv', '1');
                            myppsubs.unsubscribe();
                            this.cdr.detectChanges();
                        }
                    });
                } else {
                    const myppsub = fromEvent(document.querySelector('body'), 'mouseleave').pipe(
                        takeUntilDestroyed(this.destroyRef)
                    ).subscribe((_) => {
                        this.dialog.open(NewlettermodalComponent, {
                            width: !this.smallMode() ? '800px' : '100%',
                            height: !this.smallMode() ? '' : '100vh',
                            maxWidth: '100%',
                            panelClass: 'customModal',
                            data: {
                                email: '',
                                unsubscribe: false
                            }
                        });
                        window.localStorage.setItem(this.currentPartner.partnerid + '_newsletterpopupaktiv', '1');
                        // console.log('unsbub popup');
                        myppsub.unsubscribe();
                        this.cdr.detectChanges();
                    });
                }
            }
        }
    }

    // async checkSendScreen() {
    // this.chatService.checkScreen()
    //     .pipe(
    //         takeUntilDestroyed(this.destroyRef),
    //     ).subscribe((data: { show: boolean, from: any }) => {
    //         // protected screenService: ScreenService
    //         if (data.show && data?.from?.username?.length > 0) {
    //             this.screenService.sharescreen((canvas) => {
    //                 // console.log(canvas)
    //                 this.chatService.sendScreen(data.from, canvas);
    //             });
    //         }
    //     });
    // }

    async initScroll() {
        fromEvent(window, 'scroll').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((e) => {
            this.lastposition = window.scrollY;
            this.checkScrollPos(this.lastposition);
        });
    }

    openChat(): void {
        this.showchatpanel.set(true);
    }

    closeChat(): void {
        this.showchatpanel.set(false);
    }

    closeChatAgent(): void {
        this.showChatAgent.set(false);
    }

    async listenOpenChat() {
        this.chatService.openchat$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((open) => {
            if (open) {
                this.openChat();
            }
        });
    }

    checkCookis(prt): void {
        if (this.isBrowser()) {
            if (environment.production) {
                if (prt.partnerid?.length > 0) {
                    this.partnerid = prt.partnerid;
                    if (this.secret?.length > 0) {
                        if (this.displaySite()) {
                            if (!this.detectRobot()) {
                                this.cookieService.checkCookis(
                                    prt.googleanalyticstrackingid,
                                    prt.googletagmgid,
                                    prt.googleadsenseid,
                                    prt.googlemapid,
                                    prt.facebookpixelid,
                                    prt.matomoid,
                                    prt.matomositeid,
                                    prt.salesmanagoid,
                                    prt.awinid,
                                    prt.partnerid,
                                    prt.hidedefaultcookie,
                                    prt.usercentricsid,
                                );
                            }
                        }
                    } else {
                        if (!this.detectRobot()) {
                            this.cookieService.checkCookis(
                                prt.googleanalyticstrackingid,
                                prt.googletagmgid,
                                prt.googleadsenseid,
                                prt.googlemapid,
                                prt.facebookpixelid,
                                prt.matomoid,
                                prt.matomositeid,
                                prt.salesmanagoid,
                                prt.awinid,
                                prt.partnerid,
                                prt.hidedefaultcookie,
                                prt.usercentricsid,
                            );
                        }
                    }
                }
            }
        }
    }

    detectRobot(): boolean {
        if (this.isBrowser()) {
            const robots = new RegExp(([
                /bot/, /spider/, /crawl/, // GENERAL TERMS
                /APIs-Google/, /AdsBot/, /Googlebot/, // GOOGLE ROBOTS
                /mediapartners/, /Google Favicon/, /GOOGLE/,
                /FeedFetcher/, /Google-Read-Aloud/,
                /DuplexWeb-Google/, /googleweblight/, /SISTRIX/,
                /bing/, /yandex/, /baidu/, /duckduck/, /yahoo/, // OTHER ENGINES
                /ecosia/, /ia_archiver/, /cortex/,
                /facebook/, /instagram/, /pinterest/, /reddit/, // SOCIAL MEDIA
                /slack/, /twitter/, /whatsapp/, /Whatsapp/, /youtube/,
                /semrush/, // OTHER
            ] as RegExp[]).map((r) => r.source).join('|'), 'i'); // BUILD REGEXP + "i" FLAG

            return robots.test(navigator.userAgent);
        } else {
            return true;
        }
    }

    checkPass(ev) {
        if (ev.keyCode === 13 && this.pass === this.secret) {
            window.localStorage.setItem(this.partnerid + '_pass', this.secret);
            this.document.body.style.overflow = '';
            this.displaySite.set(true);
        } else if (ev.keyCode === 13) {
            this.pass = '';
        }
        this.cdr.detectChanges();
    }

    checkScrollPos(lastposition) {
        // faire quelque chose avec la position du scroll
        if (lastposition >= 500) {
            this.showScrollTopButton.set(true);
        } else {
            this.showScrollTopButton.set(false);
        }
    }
}
